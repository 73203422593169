.consent-layout .border-box {
  margin: 0 0 9px 0;
  padding: 4px;
  border: solid 1px;
  border-color: #a6a6a6 #f6f6f6 #f6f6f6 #a6a6a6;
  box-shadow: inset 0px 0px 1px #9a9a9a;
  background: #d7d7d7; }
  .consent-layout .border-box .title-box {
    height: 23px;
    color: #f1f1f1;
    margin: 0 !important;
    padding: 8px 0 0px 13px;
    font-weight: normal;
    background: url(//static.leagueoflegends.co.kr/common/img/common/bg/tablebg.gif) repeat 1px -37px; }

.consent-layout .scrolling {
  overflow-y: auto;
  height: 240px;
  border: solid #a9a9a9;
  border-width: 0 1px 1px 0;
  box-shadow: 1px 1px 0px #c9c9c9;
  background: #efefef; }

.consent-layout .consent {
  margin: 1em 2em 0em 2em;
  line-height: 200%;
  padding: 10px 0 18px 0; }

.consent-layout .checker {
  margin: 1em 2em 0em 2em;
  line-height: 200%;
  font-weight: bold;
  text-align: right; }
