@charset "UTF-8";
.form-container {
  position: relative; }
  .form-container .contents {
    padding-left: 1em; }
  .form-container .subtitle {
    height: 23px;
    color: #f1f1f1;
    margin: 0 !important;
    padding: 8px 0 0 13px;
    font-size: 12px;
    font-weight: normal;
    font-family: '나눔고딕','NanumGothic';
    background: url(//static.leagueoflegends.co.kr/common/img/common/bg/tablebg.gif) repeat 1px -37px; }
