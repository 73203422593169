.auth-layout .identity-layout {
  background-color: #d7d7d7;
  border-bottom: #f6f6f6 1px solid;
  border-right: #f6f6f6 1px solid;
  border-left: #a6a6a6 1px solid;
  border-top: #a6a6a6 1px solid;
  padding: 4px; }
  .auth-layout .identity-layout .self-auth {
    display: block;
    text-align: center;
    box-sizing: border-box;
    padding: 22px 14px;
    border: solid 1px #b8b8b8;
    background: #efefef;
    cursor: pointer; }
    .auth-layout .identity-layout .self-auth .warning {
      font-size: 14px;
      color: #2b2b2b; }
  .auth-layout .identity-layout .self-auth:hover {
    background-color: black;
    color: white; }
    .auth-layout .identity-layout .self-auth:hover .warning {
      color: lightgrey; }
  .auth-layout .identity-layout h1 {
    font-size: 24px;
    margin: 0 0 0.67em 0; }

.auth-layout .identity-method {
  margin: 10px 0 0px 5px;
  cursor: pointer;
  font-size: 13px; }
  .auth-layout .identity-method .method {
    transform: scale(1.2); }
