.boxed-strong-label {
  margin: 0 0 9px 0;
  padding: 4px;
  border: solid 1px;
  border-color: #a6a6a6 #f6f6f6 #f6f6f6 #a6a6a6;
  box-shadow: inset 0px 0px 1px #9a9a9a;
  background: #d7d7d7; }
  .boxed-strong-label .strong-text {
    padding: 10px 15px 11px 15px;
    min-height: 15px;
    font-size: 13px;
    color: #000;
    position: relative;
    border: solid 1px;
    border-color: #f5f5f5 #a6a6a6 #ababab #f4f4f4;
    text-shadow: 0 1px 0 #f9f9f9;
    background: #efefef url(//static.leagueoflegends.co.kr/common/img/common/bg/subbg03.png) no-repeat left top;
    box-shadow: 1px 1px 0px #c9c9c9; }
