.waiting .block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(22, 22, 22, 0.3); }

.waiting .none {
  display: none; }
