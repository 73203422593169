.page-layout .inner-title {
  padding: 13px 0 0px 0;
  height: 41px;
  font-size: 24px;
  color: #000;
  letter-spacing: -1px;
  background: url(//static.leagueoflegends.co.kr/common/img/common/titlept.gif) repeat-x left top; }

.page-layout .inner-text {
  padding: 19px 0 0 0;
  overflow: hidden;
  background: url(//static.leagueoflegends.co.kr/common/img/common/titleline.gif) no-repeat center top; }
  .page-layout .inner-text li {
    margin: 7px 0 0 0;
    font-size: 12px;
    color: #000;
    line-height: 17px;
    padding-left: 6px;
    background: url(//static.leagueoflegends.co.kr/common/img/common/bullet02.gif) no-repeat left 6px; }

.page-layout .contents-box {
  color: #000 !important; }

.page-layout .description {
  padding-top: 2em;
  padding-left: 2em;
  line-height: 200%; }
  .page-layout .description ui li {
    margin: 7px 0 0 0;
    font-size: 12px;
    color: #000;
    line-height: 17px;
    padding-left: 6px;
    background: url(//static.leagueoflegends.co.kr/common/img/common/bullet02.gif) no-repeat left 6px; }
