.simple-auth-layout .username {
  font-weight: bold;
  margin-top: 2em;
  margin-bottom: 2em; }

.simple-auth-layout .choose-methods {
  display: flex;
  display: -ms-flexbox; }
  .simple-auth-layout .choose-methods li:first-child {
    margin-right: 15px; }

.simple-auth-layout .choose-methods li {
  -ms-flex: 1;
  flex: 1;
  padding: 4px;
  border: solid 1px;
  border-color: #a6a6a6 #f6f6f6 #f6f6f6 #a6a6a6;
  box-shadow: inset 0px 0px 1px #9a9a9a;
  background: #d7d7d7;
  color: #171717; }
  .simple-auth-layout .choose-methods li a:hover {
    text-decoration: none; }

.simple-auth-layout .section {
  margin: 25px 10px 10px 10px;
  padding: 0 30px 20px 3px;
  text-align: left;
  border: none;
  color: #000; }
  .simple-auth-layout .section .label {
    width: 9em;
    font-weight: bold;
    font-size: 12px;
    display: inline-block;
    vertical-align: top;
    padding-top: 0.4em; }
  .simple-auth-layout .section .component {
    display: inline-block; }
  .simple-auth-layout .section .guide {
    margin-top: 2em; }
    .simple-auth-layout .section .guide ul {
      margin-top: 1em; }
      .simple-auth-layout .section .guide ul li {
        margin-top: 0.5em; }
  .simple-auth-layout .section .change-method {
    margin-top: 1.5em;
    font-weight: bold; }
    .simple-auth-layout .section .change-method .back {
      padding-left: 0.5em;
      text-decoration: underline;
      cursor: pointer; }
