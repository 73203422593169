.registration-components input[type="text"], .registration-components input[type="password"] {
  width: 16em;
  padding: 0 6px;
  margin-right: 7px;
  height: 21px;
  line-height: 20px;
  border: solid 1px;
  border-color: #d3d3d3 #fafafa #fafafa #ebebeb;
  border-right: none;
  box-shadow: inset 0 1px 0 #f1f1f1;
  background: #fff; }

.registration-components input[type="text"]:-ms-input-placeholder {
  color: #ccc !important; }

.registration-components input[type="button"]:disabled {
  color: #82807e; }

.registration-components .guide {
  margin: 1px 0 0 0px;
  color: #000;
  vertical-align: middle; }
  .registration-components .guide ul li {
    line-height: 1.7em; }

.registration-components .error {
  margin: 1px 0 0 0px;
  color: red; }

.registration-components .in-verification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 28.5em; }

.registration-components .out-of-verification {
  display: none; }

.registration-components .verified-result-failure {
  background: url(https://lolstatic-a.akamaihd.net/riot-kr/images/web/failure.png) no-repeat center;
  display: inline-block;
  padding-left: 2em; }

.registration-components .verified-result-success {
  background: url(https://lolstatic-a.akamaihd.net/riot-kr/images/web/success.png) no-repeat center;
  display: inline-block;
  padding-left: 2em; }

.registration-components .button {
  width: 8em; }

.registration-components .verification-code {
  display: flex;
  align-items: center;
  margin-bottom: 0; }
  .registration-components .verification-code .remaining-time {
    color: #ff0000;
    display: block;
    margin: 0 0 0 -6.6em; }

.registration-components div {
  margin-bottom: 0.7em; }
