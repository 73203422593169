.auth-method {
  display: block;
  min-height: 107px;
  box-sizing: border-box;
  padding: 35px 14px;
  border: solid 1px #b8b8b8;
  background: #efefef;
  cursor: pointer; }
  .auth-method:hover {
    color: #fff !important;
    background: #171717;
    position: relative; }
    .auth-method:hover:before {
      position: absolute;
      left: 20px;
      display: inline-block;
      content: ' ';
      width: 34px;
      height: 34px;
      background: #171717 url(//static.leagueoflegends.co.kr/common/img/common/bulletlarge.png) no-repeat left top; }
  .auth-method dl {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    padding-left: 5px; }
  .auth-method dt {
    float: left;
    margin: 0 14px 0 0;
    padding: 4px 0 0 36px;
    height: 24px;
    text-align: right;
    letter-spacing: -2px;
    font-size: 17px; }
    .auth-method dt span {
      margin-right: 8px;
      font-weight: bold; }
  .auth-method dd {
    line-height: 1.1rem; }
