.blue-button {
  margin: 0;
  padding: 0;
  border: 0 none;
  vertical-align: baseline; }
  .blue-button span {
    display: inline-block;
    vertical-align: middle;
    padding: 4px;
    text-align: center;
    border: solid 1px;
    border-color: #7b7b7b #fff #fff #7b7b7b;
    background: #d7d7d7; }
  .blue-button input {
    box-shadow: 1px 1px 0 #7b7b7b;
    width: 139px;
    margin: 0 2px 0 0;
    padding: 0 10px;
    display: block;
    font-size: 13px;
    border: solid 3px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    height: 37px;
    min-width: 139px;
    color: #2b2b2b;
    border-color: #a8b4b6 #545a67 #515664 #7a8589 !important;
    text-shadow: 0 1px 0 #899899;
    vertical-align: middle;
    align-items: flex-start;
    box-sizing: border-box;
    -webkit-appearance: push-button;
    user-select: none;
    white-space: pre; }
  .blue-button .enabled {
    background: #63757c url(//static.leagueoflegends.co.kr/common/img/common/bg/btnbgsmall.gif) no-repeat center -91px; }
  .blue-button .disabled {
    background: grey url(//static.leagueoflegends.co.kr/common/img/common/bg/btnbgsmall.gif) repeat center -262px; }
  .blue-button input:hover {
    border-color: #d5dfe1 #545a67 #515664 #8e979b !important;
    box-shadow: 1px 1px 0 #7b7b7b; }
