.orange-button {
  margin: 0;
  padding: 0;
  border: 0 none;
  vertical-align: baseline; }
  .orange-button span {
    display: inline-block;
    vertical-align: middle;
    padding: 4px;
    text-align: center;
    border: solid 1px;
    border-color: #acacac #ffffff #ffffff #acacac;
    background: #d7d7d7; }
  .orange-button input {
    box-shadow: 1px 1px 0 #7b7b7b;
    width: 139px;
    margin: 0 2px 0 0;
    padding: 0 10px;
    display: block;
    font-size: 13px;
    border: solid 3px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    height: 37px;
    min-width: 139px;
    color: #414141 !important;
    border-color: #f1c05f #bd6b24 #b6631f #e7a748;
    text-shadow: 0 1px 0 #e1bc6d;
    vertical-align: middle;
    align-items: flex-start;
    box-sizing: border-box;
    -webkit-appearance: push-button;
    user-select: none;
    white-space: pre; }
  .orange-button .enabled {
    background: #de953a url(//static.leagueoflegends.co.kr/common/img/common/bg/btnbgsmall.gif) no-repeat center -58px; }
  .orange-button .disabled {
    background: grey url(//static.leagueoflegends.co.kr/common/img/common/bg/btnbgsmall.gif) repeat center -262px;
    border-color: #a8b4b6 #545a67 #515664 #7a8589 !important; }
  .orange-button input:hover {
    border-color: #FDEABA #BF712C #B66521 #EDBD6E !important;
    box-shadow: 1px 1px 0 #7b7b7b; }
